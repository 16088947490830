import { isAxiosError } from "axios";
import {
  AuthenticatedUser,
  LoginForm,
  ServerError,
  ThunkAction,
  User,
} from "../types/index.js";
import { copiedModuleClipboard, fetch } from "../utils/utils.js";

export type PostLoginSuccessAction = { type: "POST_LOGIN_SUCCESS"; user: User };

export type Action =
  | { type: "POST_LOGIN_START" }
  | PostLoginSuccessAction
  | { type: "POST_LOGIN_ERROR"; error: ServerError };

export const postLogin =
  (form: LoginForm): ThunkAction<Promise<void>> =>
  async (dispatch) => {
    dispatch(postLoginStart());
    try {
      const res = await fetch({ dispatch }).post("users/login", form);
      const user = res.data as AuthenticatedUser;
      window.localStorage.setItem("user", JSON.stringify(user));

      copiedModuleClipboard.clear();

      dispatch(postLoginSuccess(user));
    } catch (error) {
      isAxiosError<ServerError>(error) &&
        error.response &&
        dispatch(postLoginError(error.response.data));
      throw error;
    }
  };

const postLoginStart = (): Action => ({
  type: "POST_LOGIN_START",
});

const postLoginSuccess = (user: AuthenticatedUser): Action => ({
  type: "POST_LOGIN_SUCCESS",
  user,
});

const postLoginError = (error: ServerError): Action => ({
  type: "POST_LOGIN_ERROR",
  error,
});
