import ClassNames from "classnames";
import { FunctionComponent, useEffect, useRef, useState } from "react";
import { ChromePicker } from "react-color";

const titles = {
  background: "Hintergrund",
  title: "Überschriften",
  text: "Text",
  separator: "Trenn-Hintergrund",
};
interface Props {
  id: keyof typeof titles;
  combinedId: string;
  color: string;
  onChange: (color: string) => void;
  disabled: boolean;
}

const ColorSelection: FunctionComponent<Props> = ({
  id,
  color,
  disabled,
  onChange,
}) => {
  const [showColorPicker, setShowColorPicker] = useState(false);
  const elRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClick = (e: MouseEvent) =>
      // Close the menu if an area outside it was clicked
      elRef.current &&
      !elRef.current.contains(e.target as Element) &&
      setShowColorPicker(false);

    document.addEventListener("click", handleClick, { passive: true });

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  return (
    <div
      className={ClassNames("ColorSelection", {
        "ColorSelection--disabled": disabled,
      })}
      ref={elRef}
    >
      <div className="ColorSelection__ColorItem">
        <div className="ColorSelection__Infos">
          <label className="ColorSelection__Title">{titles[id]}</label>
          <div className="ColorSelection__Code">{color}</div>
        </div>
        <div className="ColorSelection__Preview">
          <button
            disabled={disabled}
            className="ColorSelection__Color Btn--bare"
            style={{ background: color }}
            onClick={() => setShowColorPicker(true)}
          />
        </div>
      </div>
      {showColorPicker && (
        <div className="ColorSelection__Picker">
          <ChromePicker
            color={color}
            onChangeComplete={(color) => onChange(color.hex)}
            disableAlpha={true}
          />
        </div>
      )}
    </div>
  );
};

export default ColorSelection;
